<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sopFireFightingTypeCd"
            label='유형'
            v-model="searchParam.sopFireFightingTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            label="담당자"
            name="managerId"
            v-model="searchParam.managerId">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :isFirstValue="false"
            type="search"
            label="담당부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="disposeFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="disposeFlag"
            label="폐기여부"
            v-model="searchParam.disposeFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <q-table
      grid
      title=""
      :data="grid.data"
      :columns="grid.columns"
      hide-header
      hide-bottom
      :rows-per-page-options="[0]"
      virtual-scroll
    >
      <template v-slot:item="props">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
            <q-card-section class="grid-card-etc">
              <div v-if="props.row.manageNo" :class="['text-grid-etc', 'txt-box-grid text-primary-box']">
                {{ props.row.manageNo }}
              </div>
              <div :class="['text-grid-etc', 'txt-box-grid text-positive-box']">
                {{ props.row.sopFireFightingTypeName }}
              </div>
            </q-card-section>
            <q-card-section>
              <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.fireFightingName }}</div>
            </q-card-section>
          </q-card>
        </div>
      </template>
    </q-table>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
      <q-card class="mobileTableCardLayer">
        <q-card-section horizontal class="bg-grey-2 text-center">
          <q-card-section class="q-pt-xs">
            <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
export default {
  name: 'fireFightingMaster',
  mixins: [mixinFooterSearch],
  data() {
    return {
      searchParam: {
        plantCd: null,
        managerId: null,
        deptCd: null,
        sopFireFightingTypeCd: null,
        disposeFlag: 'N'
      },
      disposeFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'manageNo',
            field: 'manageNo',
            label: '설치번호',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'fireFightingName',
            field: 'fireFightingName',
            label: '소방설비',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: 'sopFireFightingTypeName',
            field: 'sopFireFightingTypeName',
            label: 'LBLTYPE',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'managerName',
            field: 'managerName',
            label: '담당자',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '담당부서',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'disposeFlag',
            field: 'disposeFlag',
            label: '폐기여부',
            align: 'center',
            sortable: true,
            style: 'width: 70px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFighting.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '소방설비 상세';
      this.popupOptions.param = {
        sopFireFightingId: row ? row.sopFireFightingId : '',
      };
      this.popupOptions.target = () => import(`${'./fireFightingMasterDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
